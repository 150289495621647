import { Menu as MuiMenu, MenuItem as MuiMenuItem } from '@mui/material';
import styles from './Menu.module.css';

export const Menu = (props) => {
  return <MuiMenu classes={{ paper: styles.menuRoot }} {...props} />;
};

export const MenuItem = (props) => {
  return <MuiMenuItem classes={{ root: styles.menuItemRoot }} {...props} />;
};
