import { LoadingDots } from '@/components/LoadingDots';
import clsx from 'clsx';
import { forwardRef } from 'react';
import styles from './Button.module.css';
import { Button as MUIButton } from '@mui/material';

export const Button = forwardRef(function Button(
  {
    children,
    className,
    onClick,
    size,
    color = 'primary',
    variant = 'contained',
    loading,
    disabled,
    startIcon = <></>,
    classes = {},
  },
  ref
) {
  return (
    <MUIButton
      className={className}
      classes={{
        root: styles.buttonRoot,
        contained: styles.contained,
        outlined: styles.outlined,
        disabled: styles.disabled,
        ...classes,
      }}
      ref={ref}
      onClick={onClick}
      disabled={loading || disabled}
      variant={variant}
      size={size}
      startIcon={startIcon}
      color={color}
    >
      {loading && <LoadingDots className={styles.loading} />}
      <span>{children}</span>
    </MUIButton>
  );
});

export const ButtonLink = forwardRef(function Button(
  { children, type, className, href, onClick, size, variant = 'invert' },
  ref
) {
  return (
    <a
      className={clsx(
        styles.button,
        type && styles[type],
        size && styles[size],
        variant && styles[variant],
        className
      )}
      ref={ref}
      href={href}
      onClick={onClick}
    >
      <span>{children}</span>
    </a>
  );
});
